import React, {
    createContext, useContext, useEffect, useMemo, useState,
} from 'react';

import AuthContext from 'contexts/auth';

const FeedbackContext = createContext();

const isTest = window.location.search.includes('testFeedback');

const surveys = [
    {
        surveyId: '38',
        delay: 0,
        permille: isTest ? 1000 : 40,
        repeatAfterDays: 180,
        triggerEvents: [
            { type: 'story' },
        ],
    },
    {
        surveyId: '39',
        delay: 0,
        permille: isTest ? 1000 : 100,
        repeatAfterDays: 180,
        triggerEvents: [
            { type: 'home' },
        ],
    },
];

function FeedbackProvider({ children }) {
    const { user, isUnauthorized } = useContext(AuthContext);

    const [isInited, setInited] = useState(false);
    const [events, setEvents] = useState([]);

    useEffect(() => {
        console.debug('FEEDBACK USER', user, { isUnauthorized });

        if (!window.Feedback || (!isUnauthorized && !user?.user_id) || isInited) {
            return;
        }

        initFeedback(user?.user_id);
    }, [user, isUnauthorized]);

    useEffect(() => {
        if (!window.FeedbackEvents || !isInited || !events.length) {
            return;
        }

        events.forEach((event) => {
            console.debug('FEEDBACK EVENT ARRAY', event.type);
            console.debug('FEEDBACK', window.FeedbackEvents.triggerWithDelay);
            window.FeedbackEvents.triggerWithDelay(event.type, event.delay);
        });

        setEvents([]);
    }, [isInited, events]);

    function initFeedback(user_id): void {
        console.debug('FEEDBACK INIT');
        window.Feedback.init({
            styles: {
                zIndex: 99999,
            },
            clientId: user_id,
            surveys,
            projectName: 'vkplay_desktop_web',
        });

        setInited(true);
    }

    function triggerEvent(type, delay) {
        if (isInited) {
            console.debug('FEEDBACK EVENT', type);
            window.FeedbackEvents.triggerWithDelay(type, delay);

            return;
        }

        setEvents([
            ...events,
            {
                type,
                delay,
            },
        ]);
    }

    const value = useMemo(() => ({
        triggerEvent,
    }), [triggerEvent]);

    return (
        <FeedbackContext.Provider value={value}>
            {children}
        </FeedbackContext.Provider>
    );
}

function withFeedback(Component) {
    return (props) => (
        <FeedbackProvider>
            <Component {...props} />
        </FeedbackProvider>
    );
}

function useFeedback() {
    const context = useContext(FeedbackContext);

    if (context === undefined) {
        throw new Error('useFeedback must be used within a FeedbackProvider');
    }

    return context;
}

export { useFeedback, withFeedback };
