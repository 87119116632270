import React, { useContext, useEffect, useMemo } from 'react';

import { AnalyticsProvider } from '@vkplay/analytics';
import AppShell from '@vkplay/eva';
import cn from 'classnames';
import { useHistory } from 'react-router-dom';

import { withBanners, useBanners } from 'contexts/banners';
import DeviceContext from 'contexts/device';
import { usePage } from 'contexts/page';
import { UpdateCounterProvider } from 'contexts/updateCounter';

import Router from 'routers/media.vkplay.ru/Router';
import getUserConsent from 'utils/getUserConsent';

import Banner from 'atoms/Banner';
import ConditionalRenderer from 'atoms/ConditionalRenderer';
import ScrollableWrapper from 'atoms/ScrollableWrapper';

import useDeviceType from 'hooks/useDeviceType';

import getProfile from './api/v3/getProfile';
import AuthContext from './contexts/auth';
import { withFeedback } from './contexts/feedback';
import initJsonLd from './json-ld';

import type { MouseEvent } from 'react';

const AppVKP: React.FC = () => {
    const { page } = usePage();
    const { setUser, setUnauthorized } = useContext(AuthContext);

    const {
        addBannersDisableCondition,
    } = useBanners();

    const history = useHistory();

    useEffect(() => {
        addBannersDisableCondition('is-gc-app', window.isGamecenter);
        addBannersDisableCondition('is-mg-app', window.isMyGamesApp);
        addBannersDisableCondition('is-stb-app', window.isSTB);
    }, []);

    const {
        isMobile, isDesktop, windowWidth, isIOS,
    } = useDeviceType();

    useEffect(() => {
        initJsonLd.app();
        initJsonLd.organization();
    }, []);

    const deviceContextValue = useMemo(() => {
        window.VKPAppConfig.isMobile = isMobile;

        return {
            isMobile, isDesktop, windowWidth, isIOS,
        };
    }, [isMobile, isDesktop, windowWidth]);

    function handleSubnavClick(e: MouseEvent<HTMLAnchorElement>) {
        e.preventDefault();

        const link = e.currentTarget.href.replace(`${window.location.origin}/media`, '');

        history.push(link);
    }

    async function handleUserGet(userData) {
        const newUser = {
            ...(userData?.session || {}),
            ...(userData?.profile || {}),
        };

        setUser(newUser || {});

        if (!newUser?.user_id) {
            setUnauthorized(true);

            return;
        }

        try {
            const profile = await getProfile({});

            setUser({
                ...newUser,
                isEditor: profile.is_editor,
                role: profile.ugc_role,
            });
        } catch (error) {
            // ignore
        }
    }

    return (
        <ScrollableWrapper id="gc-scrollbar">
            <AppShell
                preset="media"
                containerType="inline-size"
                onSubnavClick={handleSubnavClick}
                onGetUser={handleUserGet}
            >
                <AnalyticsProvider project="media" isAnalyticsAllowed={getUserConsent()}>
                    <UpdateCounterProvider>
                        <DeviceContext.Provider value={deviceContextValue}>
                            <div className={cn(
                                {
                                    'app-content_superapp': window.isMyGamesApp,
                                    'app-content_container-query': !window.isGamecenter,
                                    'app-content_media-query': window.isGamecenter,
                                },
                            )}
                            >
                                <div className="grey-mass" />
                                <ConditionalRenderer ifDesktop>
                                    {page === 'gof' ? (
                                        <Banner
                                            type="branding-gof"
                                            isDesktop
                                            isInstant
                                            withUpdateCounter
                                        />
                                    ) : (
                                        <Banner
                                            type="branding"
                                            isDesktop
                                            isInstant
                                            withUpdateCounter
                                        />
                                    )}
                                </ConditionalRenderer>
                                <Router />
                            </div>
                            <div id="vkp-footer" className="footer" />
                            <ConditionalRenderer
                                noRenderWithParam={['fromSA', 'from=newsapp', 'from=special', 'from=informer']}
                                ifMobile
                                ifNotPWA
                            >
                                <Banner
                                    type="mobile-fullscreen"
                                    isMobile
                                    isInstant
                                />
                            </ConditionalRenderer>
                            <ConditionalRenderer
                                noRenderWithParam={['fromSA', 'from=newsapp', 'from=special', 'from=informer']}
                                ifDesktop
                                ifNotPWA
                            >
                                <Banner
                                    type="fullscreen"
                                    isDesktop
                                    isInstant
                                />
                            </ConditionalRenderer>
                        </DeviceContext.Provider>
                    </UpdateCounterProvider>
                </AnalyticsProvider>
            </AppShell>
        </ScrollableWrapper>
    );
};

export default React.memo(withBanners(withFeedback(AppVKP)));
